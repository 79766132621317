import './assets/css/salesbeat_cart.css'
// var process = {env: {BUILD_HASH: 2384234, DJANGO_HOST: 'https://app.salesbeat.pro/'}};
// var process = {env: {BUILD_HASH: 2384234, DJANGO_HOST: 'https://salesbeat.local:8000'}};


(function() {
    if (!window.SB) {
        SB.log('SB не загружен');
        return;
    }
    var SB = window.SB
    SB.cartWidget = {
        html: '',
        js: '',
        loaded: false,
        onLoad: {queue: [], exec: function() {
            for (let i=0; i < SB.cartWidget.onLoad.queue.length; i++) {
                SB.cartWidget.onLoad.queue[i]()
            }
        }}
    }

    const h = process.env.DJANGO_HOST

    var cartWidgetDivId = 'sb-cart-widget'
    var cartWidgetDataUrl = `${h}/widget/cart_widget/get/`
    var applyPromoCodeUrl = `${h}/widget/apply_promo_code/`
    var wdelCss = `${h}/static/widget/css/widget.css`
    var wdel2Css = `${h}/static/widget/css/cart_widget.css`
    var wdelSrc = `${h}/static/widget/js/wdel.js`

    var cachedOptions = null
    var cachedDeliveryData = null
    var selectedCity = {
        cityCode: null,
        cityName: null,
        shortName: null,
        regionName: null
    }
        
    const defaultMapOptions = {
        withSelectButton: true, 
        mapId: 'sb-cart-pvz-map' 
    }

    SB.loadCSS(wdelCss)
    SB.loadCSS(wdel2Css)
    SB.loadScript(wdelSrc)

    if (!SB.yaMaps.ready && !SB.yaMaps.apiLoadingBegan) {
        SB.yaMaps.apiLoadingBegan = true
        SB.loadScript(SB.srcMap.yaMaps, () => {
            sbYmaps.ready(() => {
                SB.yaMaps.ready = true
                SB.yaMaps.onReady && SB.yaMaps.onReady()
            })
        }, false)
    }

    function processOptions(options) {
        if (SB.token != options.token) SB.token = options.token
        
        // Если код города не передан – попробуем взять его из кук
        if (!options.city_code) options.city_code = SB.getSelectedCity(options)

        if (options.products && Array.isArray(options.products)) {
            options.products = JSON.stringify(options.products)
        }
    }

    /**
    *   Инициализация виджета в корзине
    *
    *   :param options: Object с полями:
    *       token: обязательное поле,
    *       city_code: опциональное, 
    *       params_by: 'product_id', 'products_list' или 'params', обязательное поле,
    *       product_id: обязательное поле при ``params_by`` == 'product_id',
    *       weight: вес товара, обязательное поле при ``params_by`` == 'params',
    *       x: размер товара X, опциональное поле,
    *       y: размер товара Y, опциональное поле,
    *       z: размер товара Z, опциональное поле,
    *       price_to_pay: цена товара к оплате, обязательное поле
    *       price_insurance: цена страховки товара, обязательное поле,
    *       products: опциональное поле, Array вида:
    *           [{x: 300, y: 20, z: 10, weight: 240, price_to_pay: 3000, price_insurance: 8000},
    *            {product_id: '13342', price_to_pay: 3000, price_insurance: 8000}...],
    *       callback: function, вызываемая после выбора способа доставки
    *   
    *   :param selectedDelivery: Object – данные по выбранной ранее доставке 
    *         (для того, чтобы по умолчанию выбрать тот способ доставки, который пользователь
    *         выбирал в прошлый раз). Если выбран был ПВЗ, то ключи следующие:
    *       pvz_id: id пункта выдачи заказа,
    *       delivery_method_id: id способа доставки
    *         Если курьерка:
    *       street,
    *       house,
    *       house_block,
    *       flat,
    *       index,
    *       comment,
    *       delivery_method_id
    **/
    function init_cart(options, selectedDelivery) {
        // Для совместимости со старыми и странными интеграшками
        // (например, textil-domoi.ru передают city_by=ip и тут же передают city_code)
        if (options.city_by == 'ip') {
            delete options.city_by
            delete options.city_code
        }

        cachedOptions = Object.assign({}, options)
        
        SB.cartWidget.loaded = false
        SB.cartWidget.onLoad.queue = []

        processOptions(options)

        getCartWidget(options, function(cartWidgetHTML, city, deliveries, js) {
            let cartWidgetEl = document.getElementById(cartWidgetDivId)

            if (!cartWidgetEl) {
                SB.log(`Не удалось найти div с id="${cartWidgetDivId}"`)
            }

            selectedCity = {
                cityCode: city.city_code,
                cityName: city.city_name,
                regionName: city.region_name,
                shortName: city.short_name
            }

            cartWidgetEl.innerHTML = cartWidgetHTML

            if (!window.salesbeatCustomJs){
                var headEl = document.querySelector('head')
                var jsEl = document.createElement('script')
                jsEl.innerHTML = js
                headEl.appendChild(jsEl)
                window.salesbeatCustomJs = true
            }

            setPrevSelectedDelivery(selectedDelivery)

            let inputFields = Array.from(cartWidgetEl.querySelectorAll('.wdel2-input:not(.wdel2-promo) input'))
            for (var i=0; i < inputFields.length; i++) {
                inputFields[i].addEventListener('blur', function(e) { return validateField(e.target) })
            }

            SB.cartWidget.deliveries = deliveries
            
            SB.cartWidget.loaded = true
            SB.cartWidget.onLoad.exec()
            cartWidgetEl.hidden = false

            document.dispatchEvent(new CustomEvent(SB.cartWidgetRenderedEventName, {
                detail: SB.getCurrentDeliveryData()
            }))

            var radios = Array.from(document.querySelectorAll('input[name=delivery_code]'))
            radios.forEach(radio => {
                radio.addEventListener('change', _ => {
                    document.dispatchEvent(new CustomEvent(SB.deliveryChangedEventName, {
                        detail: SB.getCurrentDeliveryData()
                    }))
                })
            })
        })
        getCityPvz(options) 

        SB.onSelectDeliveryMethod = _getSelectDeliveryMethodCallBack(options)
    }
    SB.init_cart = init_cart

    SB.getCurrentDeliveryData = _ => {
        var deliveryDataAndFields = _getCourierData()
        if (!deliveryDataAndFields) return
            
        var deliveryData = deliveryDataAndFields.deliveryData, 
            fields = deliveryDataAndFields.fields
        
        deliveryData.city_code = selectedCity.cityCode
        deliveryData.city_name = selectedCity.cityName
        deliveryData.region_name = selectedCity.regionName
        deliveryData.short_name = selectedCity.shortName

        return deliveryData
    }

    function _getMapOptions({pvz_map_id}, forPvzMapOnly=false) {
        if (forPvzMapOnly && pvz_map_id) {
            return {
                ...defaultMapOptions,
                mapId: pvz_map_id
            };
        }
        return defaultMapOptions;
    }

    const _getSelectDeliveryMethodCallBack = (options, forPvzMapOnly=false) => {
        const mapOptions = _getMapOptions(options, forPvzMapOnly);

        return deliveryData => {
            var callback = options.callback || function() {}
            
            if (deliveryData.pvz_id) {
                // Сохраним данные о выбранном ПВЗ для того, чтобы отобразить его после перезагрузки страницы
                let pvzData = JSON.stringify(
                    getPvzData(deliveryData, {mapZoom: SB.myMaps[mapOptions.mapId].getZoom()})
                )

                SB.setCookie('selectedPvz', pvzData)
            } else {
                SB.deleteCookie('selectedPvz')

                let courierData = JSON.stringify(
                    getCourierData(deliveryData)
                )
                SB.setCookie('selectedCourier', courierData)
            }

            if (SB.myMaps[mapOptions.mapId]) {
                let fullscreenControl = SB.myMaps[mapOptions.mapId].controls.get('fullscreenControl')
                if (fullscreenControl && fullscreenControl.isSelected()) {
                    fullscreenControl.exitFullscreen()
                }
            }

            let cartWidgetEl = document.getElementById(cartWidgetDivId)
            if (cartWidgetEl) {
                cartWidgetEl.innerHTML = ''

                deliveryData.city_code = selectedCity.cityCode
                deliveryData.city_name = selectedCity.cityName
                deliveryData.region_name = selectedCity.regionName
                deliveryData.short_name = selectedCity.shortName
            }

            callback(deliveryData, options)
            document.dispatchEvent(new CustomEvent(SB.deliveryApprovedEventName, {
                detail: deliveryData
            }))
        }
    }

    function getPvzData(item, extra) {
        return {
            pvzId: item.pvz_id,
            deliveryMethodId: item.delivery_method_id,
            ...extra
        }
    }

    function _getCourierData() {
        var fields = { 
            street: document.querySelector('.wdel2-input input[name="street"]'),
            house: document.querySelector('.wdel2-input input[name="house"]'),
            house_block: document.querySelector('.wdel2-input input[name="house_block"]'),
            flat: document.querySelector('.wdel2-input input[name="flat"]'),
            index: document.querySelector('.wdel2-input input[name="index"]'),
            comment: document.querySelector('.wdel2-input textarea[name="comment"]')
        }

        var checkedDelivery = getCheckedDelivery()
        if (!checkedDelivery) return

        // добавляем информацию о доступных способах оплаты
        var payments = null
        for (let i=0; i < SB.cartWidget.deliveries.length; i++){
            let delivery = SB.cartWidget.deliveries[i]
            if (delivery.id == checkedDelivery.value){
                var payments_active = []
                if (delivery.payments && Object.keys(delivery.payments)){
                    var payment_types = Object.keys(delivery.payments)
                } else {
                    var payment_types = []
                }
                for (var j=0; j < payment_types.length; j++){
                    if (delivery.payments[payment_types[j]]){
                        payments_active.push(payment_types[j]);
                    }
                }
                payments = payments_active
                break
            }
        }

        return {
            deliveryData: {
                street: fields.street.value,
                house: fields.house.value,
                house_block: fields.house_block.value,
                flat: fields.flat.value,
                index: fields.index.value,
                comment: fields.comment.value,
                delivery_method_id: checkedDelivery.value,
                delivery_method_name: checkedDelivery.getAttribute('data-delivery_method_name'),
                delivery_price: checkedDelivery.getAttribute('data-delivery_price'),
                delivery_days: checkedDelivery.getAttribute('data-delivery_days'),
                payments: payments
            },
            fields
        }
    }

    function getCourierData(item) {
        return {
            street: item.street,
            house: item.house,
            houseBlock: item.house_block,
            flat: item.flat,
            index: item.index,
            comment: item.comment,
            deliveryMethodId: item.delivery_method_id
        }
    }

    function setCourierData(selectedCourier) {
        document.querySelector('.wdel2-input input[name="street"]').value = selectedCourier.street
        document.querySelector('.wdel2-input input[name="house"]').value = selectedCourier.house
        document.querySelector('.wdel2-input input[name="house_block"]').value = selectedCourier.houseBlock
        document.querySelector('.wdel2-input input[name="flat"]').value = selectedCourier.flat
        document.querySelector('.wdel2-input input[name="index"]').value = selectedCourier.index
        document.querySelector('.wdel2-input textarea[name="comment"]').value = selectedCourier.comment
    }

    /**
    *   Устанавливает данные о ранее выбранном способе доставки
    *
    *   Если в init_cart передан второй аргумент `selectedDelivery`, то возвращаем данные из
    *   этой переменной, инече возвращаем данные из кук (если они там есть)
    **/
    function setPrevSelectedDelivery(selectedDelivery, forPvzMapOnly) {
        forPvzMapOnly = forPvzMapOnly || false
        let selectedPvz, selectedCourier

        /* Если мы кешим данные о доставке в JS, то в первую очередь будем использовать их */
        if (cachedDeliveryData) {
            selectedDelivery = cachedDeliveryData

            if (cachedDeliveryData.promocode) {
                var $parent = document.querySelector('.wdel2-promo-code .wdel2-input')

                if ($parent) {
                    $parent.classList.add('wdel2-ok')
                    $parent.querySelector('input').value = cachedDeliveryData.promocode
                }
            }
        }

        if (selectedDelivery) {
            if (selectedDelivery.pvz_id) {
                selectedPvz = getPvzData(selectedDelivery)
            } else {
                selectedCourier = getCourierData(selectedDelivery)
            }
        } else {
            selectedPvz = SB.getCookie('selectedPvz') ? JSON.parse(SB.getCookie('selectedPvz')) : null;
            selectedCourier = SB.getCookie('selectedCourier') ? JSON.parse(SB.getCookie('selectedCourier')) : null;
        }

        // Для использования при отрисовки карт
        SB.selectedPvz = selectedPvz

        // Если идёт отрисовка только карты с ПВЗ, то нам тут больше ничего не надо
        if (forPvzMapOnly) return

        if (selectedPvz || (cachedDeliveryData && cachedDeliveryData.activeTabId == 'wdel2-tab1')) {
            document.querySelector('#wdel2-tab1').checked = true
        } else if (selectedCourier) {
            if (selectedCourier.deliveryMethodId.startsWith('post_')) {
                // Если выбрана почта – это нам на последнюю вкладку
                document.querySelector('#wdel2-tab3').checked = true
            }
            let deliveryMethods = Array.from(document.querySelectorAll('input[type=radio][name=delivery_code]'))
            for (let item of deliveryMethods) {
                if (item.value == selectedCourier.deliveryMethodId) {
                    item.checked = true
                }
            }
        }
        selectedCourier && setCourierData(selectedCourier)

        // Для простановки классов wdel2-ok тем полям, которые были заполнены ранее
        if (cachedDeliveryData) {
            var fields = _getCourierData().fields
            var fieldsForValidation = Object.keys(fields)
                .reduce((obj, key) => {
                    if (cachedDeliveryData[key]) {
                        obj[key] = fields[key]
                    }
                    return obj
                }, {}) 

            validate(fieldsForValidation)
        }
    }

    SB.selectPvz = function(event, pvzId, pvzAddress, deliveryMethodId, deliveryMethodName, 
                            deliveryPrice, deliveryDays, payments) {

        pvzAddress = pvzAddress ? unescape(pvzAddress) : null
        deliveryMethodName = deliveryMethodName ? unescape(deliveryMethodName) : null

        if (event) {
            event.preventDefault && event.preventDefault()
        }

        var deliveryData = {
            pvz_id: pvzId,
            pvz_address: pvzAddress,
            delivery_method_id: deliveryMethodId,
            delivery_method_name: deliveryMethodName,
            delivery_price: deliveryPrice,
            delivery_days: deliveryDays,
            payments: payments.split(",")
        }

        SB.onSelectDeliveryMethod(deliveryData)
    }

    SB.selectDelivery = function(event) {
        if (event) {
            event.preventDefault && event.preventDefault()
        }

        var pvzsTabEl = document.getElementById('wdel2-tab1')
        // Если открыта вкладка с ПВЗ – ничего не делаем
        if (pvzsTabEl.checked) {
            return
        }

        var deliveryDataAndFields = _getCourierData()
        var deliveryData = deliveryDataAndFields.deliveryData, 
            fields = deliveryDataAndFields.fields

        if (!validate(fields)) return
        SB.onSelectDeliveryMethod(deliveryData)
    }

    function getCheckedDelivery() {
        return document.querySelector('input[type="radio"][name="delivery_code"]:checked')
    }

    var requiredCartFields = ['street', 'house']
    var deliveryMethodsNeedsIndex = ['post_usual_cash_on_delivery', 'post_usual_prepayment', 'post_online_parcel']

    function validateField(field) {
        if (requiredCartFields.indexOf(field.name) !== -1 && (!field.value || !field.value.toString().trim())) {
            console.log('validating not passed for field', field.name, )
            field.parentElement.classList.remove('wdel2-ok')
            field.parentElement.classList.add('wdel2-error')
            return false
        }
        if (field.name === 'index' && !field.value) {
            var checkedDelivery = getCheckedDelivery()
            if (checkedDelivery && (deliveryMethodsNeedsIndex.indexOf(checkedDelivery.value) !== -1)) {
                field.parentElement.classList.remove('wdel2-ok')
                field.parentElement.classList.add('wdel2-error')
                return false
            }
        }
        field.parentElement.classList.remove('wdel2-error')
        if (field.value) {
            console.log('Wow1')
            field.parentElement.classList.add('wdel2-ok')
        } else {
            console.log('Wow2')
            field.parentElement.classList.remove('wdel2-ok')
        }
        console.log('Wow3')
        return true
    }

    function validate(fields) {
        var fieldsNames = Object.keys(fields)
        var valid = true
        for (var i = 0; i < fieldsNames.length; i++) {
            if (!validateField(fields[fieldsNames[i]])) {
                valid = false
            }
        }
        return valid && !!getCheckedDelivery()
    }

    /**
    *   Возвращает готовый HTML с данными по способам доставки
    **/
    function getCartWidget(options, callback) {
        SB.send(cartWidgetDataUrl, options, response => {
            callback(response.html, response.city, response.deliveries, response.js)
        })
    }

    function getCityPvz(options, forPvzMapOnly=false) {
        SB.send(SB.pvzUrl, options, (response) => {
            const mapOptions = _getMapOptions(options, forPvzMapOnly);

            if (SB.yaMaps.ready) {
                if (SB.cartWidget.loaded || forPvzMapOnly) {
                    SB.drawMap(response.pvzs, SB.cartWidget.deliveries, mapOptions)
                } else {
                    SB.cartWidget.onLoad.queue.push(() => SB.drawMap(response.pvzs, SB.cartWidget.deliveries, mapOptions))
                }
            } else {
                SB.yaMaps.onReady = () => SB.drawMap(response.pvzs, SB.cartWidget.deliveries, mapOptions)
            }

            if (SB.cartWidget.loaded) {
                fitYaMapsOnOpenTab()
            } else {
                SB.cartWidget.onLoad.queue.push(fitYaMapsOnOpenTab)
            }

            function fitYaMapsOnOpenTab() {
                /* Яндекс карты не любят отображаться в контенейрах с heigh=0 */
                let mapTab = document.querySelector('#wdel2-tab1')
                if (mapTab) {
                    mapTab.addEventListener('change', (event) => {
                        if (event.target.checked) {
                            if (SB.yaMaps.ready) {
                                SB.myMaps[mapOptions.mapId].container.fitToViewport();
                                if (SB.yaMaps.cachedBounds) {
                                    SB.myMaps[mapOptions.mapId].setBounds(SB.yaMaps.cachedBounds, {
                                        checkZoomRange: true,
                                        duration: 500, 
                                        zoomMargin: 3
                                    })
                                }
                            }
                        }
                    })
                }
            }
        })
    }

    /**
    *   Отображает ПВЗ на карте с возможностью выбора, аргументы как и в SB.init_cart
    **/
    SB.show_pvz_map = (options, selectDelivery) => {
        processOptions(options)
        
        getCityPvz(options, true)
        
        setPrevSelectedDelivery(selectDelivery, true)
        
        SB.onSelectDeliveryMethod = _getSelectDeliveryMethodCallBack(options, true)
    }

    SB.reinit_cart = function(saveCookie, city_code) {
        if (!saveCookie) {
            SB.deleteCookie('selectedPvz')
            SB.deleteCookie('selectedCourier')
        }

        if (!cachedOptions) return

        if (city_code) cachedOptions.city_code = city_code

        init_cart(cachedOptions)
    }

    SB.applyPromoCode = function() {
        var $promoParent = document.querySelector('.wdel2-input.wdel2-promo')
        var $promo = $promoParent.querySelector('input[name=promo_code]')
        if (!$promo.value) return

        SB.send(applyPromoCodeUrl, {
            token: SB.token,
            promocode: $promo.value
        }, response => {
            $promoParent.classList[response.exists ? 'add' : 'remove']('wdel2-ok')
            $promoParent.classList[response.exists ? 'remove' : 'add']('wdel2-error')

            if (response.exists) {
                cachedDeliveryData = {
                    activeTabId: document.querySelector('input[name=wdel2-tabs]:checked').id,
                    promocode: $promo.value,

                    ..._getCourierData().deliveryData
                }

                SB.init_cart({
                    ...cachedOptions,
                    promocode: $promo.value
                })
            }
        })
    }

    function render() {
        // let mapTab = document.querySelector('#wdel2-tab1')
        // mapTab.addEventListener('change', (event) => {
        //     if (!event.target.checked) {
        //         myMap.container.fitToViewport();
        //     }
        // })
    }

    document.addEventListener('DOMContentLoaded', render)

    SB.onChangeCartWidgetCity = SB.reinit_cart
})()
